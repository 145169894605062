import { graphql } from "gatsby"
import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import rehypeReact from "rehype-react"
import styled from "styled-components"
import Navbar from "../components/Navbar/Navbar"
import PagesContext from "../contexes/PagesContext"
import getNamedPartials from "../partials"
import { respond } from "../_respond"
import Layout from "./Layout"
import "./remark.css"

const ContentWrapper = styled.article`
  padding: 2rem 4rem;
  margin: 0;
  font-family: "Lato";

  ${respond.mobile`
    padding: 0;
  `}

  ${respond.xs`
    padding: 0;
    margin: 0
  `}

  ${respond.sm`

    margin: 0
  `}

  ${respond.md`

  margin: 0
  `}

  ${respond.lg`

  margin: 0;
  `}
`

export default function GridNavigationTemplate(props) {
  const { data } = props
  const { frontmatter, htmlAst } = data.page
  const partials = getNamedPartials()
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: partials,
  }).Compiler

  const filePath = data.page.fileAbsolutePath.substring(
    data.page.fileAbsolutePath.lastIndexOf("/data/"),
    data.page.fileAbsolutePath.length,
  )

  return (
    <>
      <Fragment>
        <Helmet title={frontmatter.title} />{" "}
        <PagesContext.Provider
          value={{
            all: data,
            current: {
              frontmatter: frontmatter,
              filePath: filePath,
            },
          }}
        >
          <Layout>
            <Fragment>
              <Navbar> </Navbar>
              <ContentWrapper> {renderAst(htmlAst)} </ContentWrapper>
            </Fragment>{" "}
          </Layout>
        </PagesContext.Provider>{" "}
      </Fragment>{" "}
    </>
  )
}

export const pageQuery = graphql`
  query ($path: String!) {
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      html
      frontmatter {
        path
        title
        subTitle
        banner
      }
      fileAbsolutePath
    }
    allPages: allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            path
            title
            topic
            subTitle
          }
        }
      }
    }
  }
`
